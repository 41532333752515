import apiClient from '@/services/axios'

export const getManufactures = async name => {
  const res = await apiClient.get('/admin/manufacturers', { params: { name } })
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const getManufacturesById = async id => {
  const res = await apiClient.get('/admin/manufacturers/' + id)
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const editManufacturesById = async (id, payload) => {
  const res = await apiClient.put(`/admin/manufacturers/${id}`, payload)
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const createManufacture = async payload => {
  const res = await apiClient.post('/admin/manufacturers', payload)
  if (res) {
    return res.data
  } else {
    return null
  }
}
